import { Component } from 'react'
import api from "../../api/api"


class ServerStatusUnknown extends Component {
  
  serverStatus = {}
  icon_url = "./images/unknown.svg"
  header = "It doesn't look like anything to me..."
  genericMessage = "The information you gave us seems to be correct, but we just can't figure out what it is..."

  constructor(props) {
    super(props)
  }

  render() {
    return (
    <div className={"md:flex flex-auto w-full md:w-5/6 max-w-prose m-auto bg-white rounded-xl shadow-md overflow-hidden divide-x " + this.extraClasses}>

      {/* Card Image */}
      <div className="flex-none md:w-36 md:block ">
        <img className="w-full h-16 md:w-none md:h-full object-none md:object-cover" src={this.icon_url} alt="what is this?" />
      </div>

      {/* Card Content */}
      <div className="flex flex-col flex-auto">

        {/* Card Header */}
        <div className="flex flex-row items-center p-2">
          <div className="flex w-full"><div><p className="text-left w-120 md:max-w-1xl font-medium truncate">{this.header}</p></div></div>
        </div>

        <hr className='p-1' />

        {/* Card Body */}
        <div className='mx-6 my-2'>
          {this.props.message ? this.props.message : this.genericMessage}
        </div>

      </div>
    </div>
    )
  }
}

export default ServerStatusUnknown;