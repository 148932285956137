import { Client as Appwrite, Databases, Account, Functions } from 'appwrite';
import { Server } from '../utils/config';

let api = {
  sdk: null,

  provider: () => {
    if (api.sdk) {
      return api.sdk;
    }
    let appwrite = new Appwrite();
    appwrite.setEndpoint(Server.endpoint).setProject(Server.project);
    const account = new Account(appwrite);
    const database = new Databases(appwrite, Server.databaseID);
    const functions = new Functions(appwrite);
    const client = appwrite;  
    
    api.sdk = { database, account, functions, client };
    return appwrite;
  },

  createExecution: (functionId, data, async) => {
    console.log(`Invoking function: ${functionId} with data: ${data}`)
    return api.provider().functions.createExecution(functionId, JSON.stringify(data), async);
  },

  getExecution: (functionId, executionId) => {
    return api.provider().functions.getExecution(functionId, executionId);
  },

  listExecutions: (functionId, limit, offset, search, cursor, cursorDirection) => {
    return api.provider().functions.listExecutions(functionId, limit, offset, search, cursor, cursorDirection);
  },

  subscribeExecution: (functionId, executionId) => {
    api.provider().subscribe('executions', response => {
      console.log(response.payload);
    });
  },

  retryBuild: (functionId, deploymentId, buildId) => {
    return api.provider().functions.retryBuild(functionId, deploymentId, buildId);
  },

  createOAuth2Session: (authType) => {
    return api.provider().account.createOAuth2Session(authType)
  },

  createAnonSession: () => {
    
    console.log("account: " + JSON.stringify(api.provider().account))
    return api.provider().account.createAnonymousSession();
  },

  createAccount: (email, password, name) => {
    return api.provider().account.create('unique()', email, password, name);
  },

  getAccount: () => {
    
    return api.provider().account.get();
  },

  getSessions: () => {
    return api.provider().account.getSessions();
  },

  createSession: (email, password) => {
    return api.provider().account.createEmailSession(email, password);
  },

  deleteCurrentSession: () => {
    
    return api.provider().account.deleteSession('current');
  },

  subscribeDocument: (collection_id, document_id, call_back) => {
    api.provider().subscribe(`collections.${collection_id}.documents.${document_id}`, response => {
      return call_back(response)
    });
  },

  getDocument: (database_id, collection_id, document_id) => {
    const promise = api.provider().database.getDocument(database_id, collection_id, document_id);
    promise.then(function (response) {
      console.log("response", response)
      return response
    }, function (error) {
      console.log("Failure: ", error)
    });
  },

  createDocument: (collectionId, data, read, write) => {
    return api
      .provider()
      .database.createDocument(collectionId, 'unique()', data, read, write);
  },

  listDocuments: (collectionId) => {
    return api.provider().database.listDocuments(collectionId);
  },

  updateDocument: (collectionId, documentId, data, read, write) => {
    return api
      .provider()
      .database.updateDocument(collectionId, documentId, data, read, write);
  },

  deleteDocument: (collectionId, documentId) => {
    return api.provider().database.deleteDocument(collectionId, documentId);
  },
};

export default api;
