import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Login from "./pages/Login/Login";
import Landing from "./pages/Landing/Landing";
import Dashboard from "./pages/Dashboard/Dashboard";
import { Component, setState } from 'react'
import { ReactSession } from 'react-client-session';
import { Account,Client } from 'appwrite';
import { Server } from './utils/config';

ReactSession.setStoreType("localStorage");
ReactSession.set("navSubTitle", "");

const initialState = {
  authenticated: false,
  user: {}
}

class App extends Component {

  client = new Client().setEndpoint(Server.endpoint).setProject(Server.project);
  account = new Account(this.client)

  constructor(props) { 
    super(props) 
    this.state = initialState
    this.getAccount = this.getAccount.bind(this)
    this.logout = this.logout.bind(this)
  }

  logout(){
    const promise = this.account.deleteSessions();
    promise.then((response) => {
        console.log(response); // Success
        this.setState(
          this.state = initialState
        )
        
    }, (error) => {
        console.log(error); // Failure
    });
  }

  getAccount(){
    const promise = this.account.get()
    promise.then((response) => {
      console.log("Account:", response); // Success
      this.setState(
        this.state = {
          authenticated: true,
          user: response
        }
      )
      return response
    }, (error) => {
      console.log("Error:", error)
    });
  }

  componentDidMount(){
    this.getAccount()
  }

  render() {
    return (
      <>
      <section>
      <nav className="px-3 relative block h-14 w-full flex flex-wrap items-center justify-between py-3 bg-gray-700 text-gray-200 shadow-lg navbar navbar-expand-lg navbar-light">
        <div className="flex flex-row w-full">
          <div className="flex items-center max-w-min text-2xl cursor-pointer">
            <a href="/" className="bg-clip-text">Turnbros</a>
          </div>
          <div className="flex w-full justify-end">
          { this.state.authenticated ?
            <div className="flex items-center grow mx-2">
              <div className="rounded-full overflow-hidden mx-1">
                <img className="w-8 object-cover" src="https://icon-library.com/images/generic-user-icon/generic-user-icon-24.jpg" />
              </div>
              <p>{this.state.user.name} -</p>
            </div> : ''
          }
            <div className="flex items-center max-w-min text-sm cursor-pointer">
              { this.state.authenticated ? <><a href="#" onClick={this.logout} className="bg-clip-text">LOGOUT</a></> : <a href="/login" className="bg-clip-text">LOGIN</a> }
            </div>
          </div>
        </div>
      </nav>
      </section>
      <BrowserRouter>
        <Switch> 
          <Route path="/dashboard">
          { this.state.authenticated ? <Dashboard /> : <Redirect to="/login" /> }
          </Route>
          <Route path="/login" component={Login} />
          <Route exact path="/" component={Landing} />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
      </>
    )
  }  
}
export default App