import { Component } from 'react'
import { Client, Account } from "appwrite";
import { Server } from '../../utils/config';

const client = new Client().setEndpoint(Server.endpoint).setProject(Server.project);
const account = new Account(client);

class EmailLogin extends Component {
  constructor(props) {
    super(props)

    this.handleEmailAuthentication = this.handleEmailAuthentication.bind(this)
  }
  
  handleEmailAuthentication(e){
    e.preventDefault()

    const promise = account.createEmailSession(
      this.emailAddress.value,
      this.emailPassword.value
    );
    promise.then((response) => {
        console.log("Login Response:", response);
        this.props.authCallback()
    }, (error) => {
        console.log("Error:", error);
    });
  }

  render() {
    return (
      <>
        <div className="relative z-10 " aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">


              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-lg">
                <form className="m-8 space-y-6" onSubmit={(e) => {this.handleEmailAuthentication(e)}}>
                  <p className='text-xl'>Email Authentication</p>
                  <input type="hidden" name="remember" value="true" />
                  <div className="-space-y-px rounded-md shadow-sm">
                    <div>
                      <label htmlFor="email-address" className="sr-only">Email address</label>
                      <input ref={emailAddress => (this.emailAddress = emailAddress)} id="email-address" name="email" type="email" autoComplete="email" required className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" placeholder="Email address" />
                    </div>
                    <div>
                      <label htmlFor="password" className="sr-only">Password</label>
                      <input ref={emailPassword => (this.emailPassword = emailPassword)} id="password" name="password" type="password" autoComplete="current-password" required className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" placeholder="Password" />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                      <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">Remember me</label>
                    </div>

                    <div className="text-sm">
                      <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">Forgot your password?</a>
                    </div>
                  </div>

                  <div>
                    <button type="submit" className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">

                        <svg className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fillRule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clipRule="evenodd" />
                        </svg>
                      </span>
                      Sign in
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default EmailLogin;