import { Component } from 'react'
import ServerStatus from '../Landing/ServerStatus'
import { Client, Functions, Databases, Query } from "appwrite";
import { Server } from '../../utils/config';

const client = new Client().setEndpoint(Server.endpoint).setProject(Server.project);
const functions = new Functions(client);
const databases = new Databases(client);

class Dashboard extends Component {
  server_records = []

  // This is triggered when the user hits enter.
  handleKeypress = e => {
    if (e.keyCode === 13 && this.endpoint.value.length > 0) {
      // First we look up the endpoint to get the info used in the status card.
      const promiseFindEndpoint = this.findEndpoint(this.endpoint.value)
      promiseFindEndpoint.then((endpointStatus) => {
        // Next we need to add the endpoing status to the state object so it gets rendered.
        this.renderEndpoint(endpointStatus)
        // Lastly, we add an entry to the database so the user sees the endpoint when they login later.
        const promiseStoreEndpoint = this.storeEndpoint(endpointStatus)
        promiseStoreEndpoint.then((response) => {
          console.log(response)
          this.endpoint.value = ""
        }, (error) => {
          console.log(error)
        })
      }, (error) => {
        console.log("Endpoint lookup failed!", error)
      })
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      servers: []
    }

    // Performs the initial pre-render load of the dashboard
    this.loadDashboard = this.loadDashboard.bind(this)
    // Gets a list of the configured endpointIDs from the user database
    this.getEndpointIDList = this.getEndpointIDList.bind(this)
    // For each endpoint returned above, get the info needed for the server status card.
    this.populateEndpointList = this.populateEndpointList.bind(this)


    this.findEndpoint = this.findEndpoint.bind(this)
    this.storeEndpoint = this.storeEndpoint.bind(this)
    this.renderEndpoint = this.renderEndpoint.bind(this)
  }


/*  ####################################
    ##    Load dashboard endpoints    ##
    #################################### */
  async loadDashboard() {
    // This gets a list of configured endpoints from `getEndpointIDList` below
    const endpointIDListPromise = this.getEndpointIDList()
    endpointIDListPromise.then((endpointIDList) => {
      // This updates the `this.state.servers` state object
      console.log("Loading the dashboard", endpointIDList)
      const populateEndpointListPromise = this.populateEndpointList(endpointIDList)
      populateEndpointListPromise.then((response) => {
        console.log("Endpoint statuses populated!", response)
      }, (error) => {
        console.log("Failed to get the endpoint statuses", error)
      })
    }, (error) => {
      console.log("Failed to load the dashboard!", error); // Failure
    })
  }

  async getEndpointIDList() {
    return databases.listDocuments(Server.userDatabaseID, Server.configuredEndpointsID);
  }

  async populateEndpointList(endpointIDList) {
    let server_records = []
    for (let i = 0; i < endpointIDList.length; i++) {
      let record = await databases.getDocument(Server.databaseID, Server.collectionID, endpointIDList[i]);
      server_records.push(record)
    } 
    this.setState(
      this.state = {
        servers: server_records
      }
    )
    return this.state.servers
  }


/*  ###################################
    ##    Add Dashboard Endpoints    ##
    ################################### */
  // This will be called every time the user adds a server to their dashboard.
  // This will go through the regular discovery/retreival process.
  async findEndpoint(input_endpoint) {
    let endpoint = JSON.stringify({"endpoint": input_endpoint})
    let server_record = await functions.createExecution("63c0d9330938d8dc2772", endpoint, false);
    console.log("received response: ", server_record.response)
    return JSON.parse(server_record.response)
  }

  async storeEndpoint(endpoint) {
    console.log("Store endpoint", endpoint)
    // create the database entry
    // TODO: this
    // Add an entry to the database
    //databases.createDocument(Server.userDatabaseID, Server.configuredEndpointsID, "")
  }

  async renderEndpoint(endpoint) { 
    console.log("Render Endpoing", endpoint)
    this.server_records.push(endpoint)
    this.setState(
      this.state = {
        servers: this.server_records
      }
    )
  }

  // This is called right before the page is rendered.
  componentDidMount(){
    this.loadDashboard()
  }

  render() {
    return (
      <>
        <section className="flex container mx-auto justify-center h-screen w-screen">
          <div className="flex flex-col h-5/6 w-5/6 my-auto bg-white rounded-xl shadow-md">
            <div className="flex flex-row">
              <p className="flex font-sans justify-start m-4 text-xl md:text-2xl lg:text-3xl  ">
                Watchtower
              </p>
              <div className='flex justify-end items-center w-full'>
                <input 
                  ref={endpoint => (this.endpoint = endpoint)}
                  type="text"
                  placeholder="Add Server"
                  onKeyUp={this.handleKeypress}
                  className="flex justify-center h-2/3 mx-2 py-3 px-2 py-3 px-2 rounded-lg outline-slate-700 focus:ring-2" />
              </div>
            </div>
            <hr />
            <div className="grid grid-flow-row-dense grid-cols-2 grid-rows-3 gap-4 my-5">
              {this.state.servers.map(server => (
                <ServerStatus key={server.endpoint} status={server} className="transition duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-xl" />
              ))}
            </div>
          </div>
        </section>
      </>
    )
  }  
}
export default Dashboard