import { Component } from "react";
import { Account,Client } from 'appwrite';
import { Server } from '../../utils/config';
import EmailLogin from './EmailLogin';
import { ReactSession } from 'react-client-session';

class Login extends Component {

  client = new Client().setEndpoint(Server.endpoint).setProject(Server.project);
  account = new Account(this.client)
  begin_email_auth = false

  constructor(props) {
    super(props)
    this.state = {
      begin_email_auth: this.begin_email_auth
    }
    this.checkLoginState()
    this.authCallback = this.authCallback.bind(this)
  }

  checkLoginState(){
    const promise = this.account.get()
    promise.then((response) => {
      console.log("Account:", response); // Success
        this.props.history.push("/dashboard")
    }, (error) => {
      console.log("Error:", error)
    });
  }

  authCallback() {
    this.setState(
      this.state = {
        begin_email_auth: false,
      }
    )
    this.props.history.push("/dashboard")
    window.location.reload(false);
  }

  render() {
    const initiateEmailAuth = () => {
      this.begin_email_auth = true
      this.setState(
        this.state = {
          begin_email_auth: this.begin_email_auth,
        }
      )
    }

    const handleOAuthLogin = (oauthType) => {
      const promise = this.account.createOAuth2Session(oauthType, "https://turnbros.app/dashboard")
      promise.then(function (response) {
        console.log(response);
        ReactSession.set("authenticated", true);
        ReactSession.set("user", response);
        this.authCallback()
      }, function (error) {
          console.log(error);
          ReactSession.set("authenticated", false);
      });
    };
    
    
    return (
      
      <>
      <section>
        {this.state.begin_email_auth ? <EmailLogin authCallback={this.authCallback} /> : ''}
      </section>
      <section className="flex container mx-auto justify-center h-screen w-full md:w-128">
          <div className="flex flex-col my-auto justify-start grow p-6 text-center bg-white rounded-xl shadow-md">
            <p className="my-2 text-l md:text-xl lg:text-2xl font-medium text-gray-800 ">
              Select An Authentication Provider
            </p>
            <hr className="mb-3"/>
            <div className="flex flex-col">
              
              <div onClick={initiateEmailAuth} className="flex flex-row justify-center w-full border-2 mt-2 hover:text-white hover:border-transparent hover:bg-gray-900 focus:outline-none transition duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-xl cursor-pointer">
                {/* Card Logo */}
                <div className="flex items-center w-7 m-4">
                  <img className="object-cover rounded-sm" src="http://cdn.onlinewebfonts.com/svg/img_237869.png" />
                </div>
                {/* Card Name */}
                <div className="flex w-full justify-start">
                  <div className="flex items-center max-w-fit text-sm md:text-md lg:text-lg text-slate-500 font-light tracking-normal">
                    Continue with Email
                  </div>
                </div>
              </div>
              
              {Server.authProviders.map((provider, index) => (
              <div key={index} onClick={() =>{handleOAuthLogin(provider.name.toLowerCase())}} className="flex flex-row justify-center w-full border-2 mt-2 hover:text-white  hover:border-transparent hover:bg-gray-900 focus:outline-none transition duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-xl cursor-pointer">
                {/* Card Logo */}
                <div className="flex items-center w-7 m-4 ">
                  <img className="object-cover rounded-sm" src={provider.logo} />
                </div>
                {/* Card Name */}
                <div className="flex w-full justify-start">
                  <div className="flex items-center max-w-fit text-sm md:text-md lg:text-lg text-slate-500 font-light tracking-normal">
                    Continue with {provider.name}
                  </div>
                </div>
              </div>
              ))}
            </div>
          </div>

      </section>
      </>
    )
  }  
}
export default Login