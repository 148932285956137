import ServerStatus from "./ServerStatus"
import ServerStatusAlert from "./ServerStatusAlert"
import ServerStatusUnknown from "./ServerStatusUnknown"
import { Component, React } from 'react'
import { Client, Functions } from "appwrite";
import { Server } from '../../utils/config';

const client = new Client().setEndpoint(Server.endpoint).setProject(Server.project);
const functions = new Functions(client);

class Landing extends Component {
  serverUnknown = false
  serverError = false
  gettingStatus = false
  serverStatus = null
  handleKeypress = e => {
    if (e.keyCode === 13 && this.endpoint.value.length > 0) {
      this.handleCheckServer();
    }
  }

  constructor(props) {
    super(props)

    // initialize state
    this.state = {
      server_unknown: this.serverUnknown,
      server_error: this.serverError,
      getting_status: this.gettingStatus,
      server_status: this.serverStatus
    };

    this.updateState = this.updateState.bind(this)
    this.handleCheckServer = this.handleCheckServer.bind(this)
    this.checkServerSuccess = this.checkServerSuccess.bind(this)
    this.checkServerFailed = this.checkServerFailed.bind(this)
  }

  async handleCheckServer() {
    this.serverError = false;
    this.serverUnknown = false;
    this.gettingStatus = true;
    this.updateState()
    
    let endpoint = JSON.stringify({"endpoint": this.endpoint.value})
    const serverRecord = await functions.createExecution("63c0d9330938d8dc2772", endpoint, false);
    if(serverRecord.status === "completed"){
      // Display the server record.
      this.checkServerSuccess(serverRecord.response)
    } else {
      // Handle failure
      this.checkServerFailed(serverRecord.stderr)
    }
  }

  // If the server lookup is a success, then we update state
  async checkServerSuccess(serverRecordResponse){
    let recordJSON = JSON.parse(serverRecordResponse)
    // Something happened and we know know what kind of server this is
    if(recordJSON.kind == "unknown"){
      this.gettingStatus = false
      this.serverStatus = null
      this.serverError = false
      this.serverUnknown = true
      this.updateState()
    } else {
      // Everything was a success and we know what the server is
      this.gettingStatus = false
      this.serverStatus = JSON.parse(serverRecordResponse)
      this.serverError = false
      this.serverUnknown = false
      this.updateState()
    }
  }

  // If the server lookup failed, we handle the error here.
  async checkServerFailed(error){
    console.log("Failed to get server information", error)
    this.gettingStatus = false
    this.serverStatus = null
    this.serverError = true
    this.serverUnknown = false
    this.updateState()
  }

  async updateState(){
    this.setState(
      this.state = {
        server_unknown: this.serverUnknown,
        server_error: this.serverError,
        getting_status: this.gettingStatus,
        server_status: this.serverStatus
      }
    )
  }

  render() {
    const submitButton = <input
      className="bg-clip-text"
      type="button"
      value="Get Status"
      onClick={this.handleCheckServer}
    />

    const loadingAnimation = <div className="flex justify-center items-center m-10">
      <div role="status">
        <svg aria-hidden="true" className="inline w-16 h-16 mr-2 text-gray-200 animate-spin dark:text-gray-100" viewBox="0 0 100 101" fill="" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#374151"/>
        </svg>
      </div>
    </div>

    return (
      <>
        <section className="flex container mx-auto h-screen max-h-128">
          <div className="flex flex-col mx-auto justify-center p-6 text-center">
            <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold text-gray-900">Welcome</h1>
            <p className="my-4 text-l md:text-xl lg:text-2xl font-light text-gray-900">
              Lookup video game server status by DNS name or IP address
            </p>

            <div className="flex flex-row w-4/5 items-center gap-2 lg:gap-3 mx-auto">

              <div className="flex-1">
                <input
                  ref={endpoint => (this.endpoint = endpoint)}
                  type="text"
                  className="w-full text-sm md:text-md lg:text-l font-light py-3 px-2 py-3 px-2 rounded-lg border-0 focus:ring-2 focus:ring-gray-800 transition duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-xl shadow-md"
                  placeholder="DNS name or IP address and port"
                  onKeyUp={this.handleKeypress}
                />
              </div>

              <div onClick={this.handleCheckServer} className="flex-none h-full justify-center rounded-lg py-3 px-2 shadow-md text-sm md:text-md lg:text-l font-medium text-slate-500 hover:text-white  hover:border-transparent hover:bg-gray-900 focus:outline-none transition duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-xl">
                {submitButton}
              </div>

            </div>

            {this.state.getting_status || ((this.state.server_status != null) || this.state.server_unknown || this.state.server_error) ? <hr className="my-6" /> : ''}
            <div className="grid grid-flow-row auto-rows-max gap-5 overflow-y-auto p-2">
              {this.state.server_error ? <ServerStatusAlert message={null} /> : ''}
              {this.state.server_unknown ? <ServerStatusUnknown message={null} /> : ''}
              {this.state.getting_status ? loadingAnimation : ''}
              {(this.state.server_status != null) && !this.state.getting_status ? <ServerStatus key={this.state.server_status.endpoint} status={this.state.server_status} /> : '' }
            </div>
          </div>
        </section>
      </>
    )
  }
}
export default Landing