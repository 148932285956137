import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsRotate, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { Statistic } from 'antd';
import { Component } from 'react'
import api from "../../api/api"


class ServerStatus extends Component {
  
  serverStatus = {}

  constructor(props) {
    super(props)

    this.state = {};

    this.serverStatus = this.props.status
    this.extraClasses = this.props.className
    this.updateState = this.updateState.bind(this)
    this.refreshStatus = this.refreshStatus.bind(this)
    this.handleServerRecordUpdate = this.handleServerRecordUpdate.bind(this)

    this.updateState(this.serverStatus)
  }
  
  async handleServerRecordUpdate(response) {
    console.log(response)
  }

  async updateState(freshStatus){
    this.serverStatus = freshStatus
    this.setState(
      this.state = {
        "id": freshStatus.id,
        "endpoint": freshStatus.endpoint,
        "kind": freshStatus.kind,
        "icon_url": freshStatus.icon_url,
        "name": freshStatus.name,
        "description": freshStatus.description,
        "players_max": freshStatus.players_max,
        "players_online": freshStatus.players_online,
        "last_latency": freshStatus.last_latency,
        "version": freshStatus.version,
        "protocol_version": freshStatus.protocol_version,
        "protocol_name": freshStatus.protocol_name
      }
    )
  }

  async refreshStatus() {
    console.log("Refresh Server Status")
    let lookupResponse = await api.provider().functions.createExecution("63c0d9330938d8dc2772", JSON.stringify(this.serverStatus), false);
    let response = JSON.parse(lookupResponse.response)
    this.updateState(response)
  }

  render() {
    return (
    <div className={"md:flex flex-auto w-full md:w-5/6 m-auto bg-white rounded-xl shadow-md overflow-hidden " + this.extraClasses}>

      {/* Card Image */}
      <div className="flex-none md:w-36 md:block ">
        <img className="w-full h-16 md:w-none md:h-full object-none md:object-cover" src={this.serverStatus.icon_url} alt="Game Server Icon" />
      </div>

      {/* Card Content */}
      <div className="flex flex-col flex-auto">

        {/* Card Header */}
        <div className="flex flex-row items-center p-2">
          <div className="flex w-full"><div><p className="text-left w-60 md:w-120 md:max-w-xl pl-1 font-medium truncate">{this.serverStatus.name}</p></div></div>
          <div className="flex-none w-10"><FontAwesomeIcon icon={faCircleInfo} className="font-light fa-xl" /></div>
          <div className="flex-none w-10"><FontAwesomeIcon icon={faArrowsRotate} className="font-light fa-xl" onClick={this.refreshStatus} /></div>
        </div>

        <hr className='p-1' />

        {/* Card Body */}
        <div className='flex flex-row m-2'>
          <div className='flex-auto'><Statistic title="Players Online" value={this.serverStatus.players_online} suffix={"/ " + this.serverStatus.players_max} /></div>
          <div className='flex-auto'><Statistic title="Latency (ms)" value={this.serverStatus.last_latency} precision={5} /></div>
        </div>

      </div>
    </div>
    )
  }
}

export default ServerStatus;