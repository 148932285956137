export const Server = {
    webEndpoint : process.env.WEB_ENDPOINT,
    endpoint : process.env.REACT_APP_ENDPOINT,
    project: process.env.REACT_APP_PROJECT,
    databaseID : process.env.REACT_APP_DATABASE_ID,
    collectionID : process.env.REACT_APP_COLLECTION_ID,
    userDatabaseID : "63c6d8586a78fc76551f",
    configuredEndpointsID : "63c6d8a002be300894d9",

    authProviders : [
        {
            name: "Discord",
            logo: "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20127.14%2096.36%22%3E%3Cg%20id%3D%22%E5%9B%BE%E5%B1%82_2%22%20data-name%3D%22%E5%9B%BE%E5%B1%82%202%22%3E%3Cg%20id%3D%22Discord_Logos%22%20data-name%3D%22Discord%20Logos%22%3E%3Cg%20id%3D%22Discord_Logo_-_Large_-_White%22%20data-name%3D%22Discord%20Logo%20-%20Large%20-%20White%22%3E%3Cpath%20d%3D%22M107.7%2C8.07A105.15%2C105.15%2C0%2C0%2C0%2C81.47%2C0a72.06%2C72.06%2C0%2C0%2C0-3.36%2C6.83A97.68%2C97.68%2C0%2C0%2C0%2C49%2C6.83%2C72.37%2C72.37%2C0%2C0%2C0%2C45.64%2C0%2C105.89%2C105.89%2C0%2C0%2C0%2C19.39%2C8.09C2.79%2C32.65-1.71%2C56.6.54%2C80.21h0A105.73%2C105.73%2C0%2C0%2C0%2C32.71%2C96.36%2C77.7%2C77.7%2C0%2C0%2C0%2C39.6%2C85.25a68.42%2C68.42%2C0%2C0%2C1-10.85-5.18c.91-.66%2C1.8-1.34%2C2.66-2a75.57%2C75.57%2C0%2C0%2C0%2C64.32%2C0c.87.71%2C1.76%2C1.39%2C2.66%2C2a68.68%2C68.68%2C0%2C0%2C1-10.87%2C5.19%2C77%2C77%2C0%2C0%2C0%2C6.89%2C11.1A105.25%2C105.25%2C0%2C0%2C0%2C126.6%2C80.22h0C129.24%2C52.84%2C122.09%2C29.11%2C107.7%2C8.07ZM42.45%2C65.69C36.18%2C65.69%2C31%2C60%2C31%2C53s5-12.74%2C11.43-12.74S54%2C46%2C53.89%2C53%2C48.84%2C65.69%2C42.45%2C65.69Zm42.24%2C0C78.41%2C65.69%2C73.25%2C60%2C73.25%2C53s5-12.74%2C11.44-12.74S96.23%2C46%2C96.12%2C53%2C91.08%2C65.69%2C84.69%2C65.69Z%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
        },
        {
            name: "Google",
            logo: "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 48 48'%3E%3Cdefs%3E%3Cpath id='a' d='M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z'/%3E%3C/defs%3E%3CclipPath id='b'%3E%3Cuse xlink:href='%23a' overflow='visible'/%3E%3C/clipPath%3E%3Cpath clip-path='url(%23b)' fill='%23FBBC05' d='M0 37V11l17 13z'/%3E%3Cpath clip-path='url(%23b)' fill='%23EA4335' d='M0 11l17 13 7-6.1L48 14V0H0z'/%3E%3Cpath clip-path='url(%23b)' fill='%2334A853' d='M0 37l30-23 7.9 1L48 0v48H0z'/%3E%3Cpath clip-path='url(%23b)' fill='%234285F4' d='M48 48L17 24l-4-3 35-10z'/%3E%3C/svg%3E"
        },
        {
            name: "Github",
            logo: "data:image/svg+xml;charset=utf-8,%3Csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0C4.477 0 0 4.36 0 9.74c0 4.304 2.865 7.955 6.839 9.243.5.09.682-.211.682-.47 0-.23-.008-.843-.013-1.656-2.782.588-3.369-1.306-3.369-1.306-.454-1.125-1.11-1.425-1.11-1.425-.908-.604.069-.592.069-.592 1.003.069 1.531 1.004 1.531 1.004.892 1.488 2.341 1.059 2.91.81.092-.63.35-1.06.636-1.303-2.22-.245-4.555-1.081-4.555-4.814 0-1.063.39-1.933 1.029-2.613-.103-.247-.446-1.238.098-2.578 0 0 .84-.262 2.75.998A9.818 9.818 0 0 1 10 4.71c.85.004 1.705.112 2.504.328 1.909-1.26 2.747-.998 2.747-.998.546 1.34.203 2.331.1 2.578.64.68 1.028 1.55 1.028 2.613 0 3.742-2.339 4.566-4.566 4.807.359.3.678.895.678 1.804 0 1.301-.012 2.352-.012 2.671 0 .261.18.564.688.47C17.137 17.69 20 14.042 20 9.74 20 4.36 15.522 0 10 0z' fill='%23161514' fill-rule='evenodd'/%3E%3C/svg%3E"
        },
        {
            name: "Microsoft",
            logo: "data:image/svg+xml;charset=utf-8,%3Csvg width='221' height='221' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none'%3E%3Cpath fill='%23F1511B' d='M104.868 104.868H0V0h104.868z'/%3E%3Cpath fill='%2380CC28' d='M220.654 104.868H115.788V0h104.866z'/%3E%3Cpath fill='%2300ADEF' d='M104.865 220.695H0V115.828h104.865z'/%3E%3Cpath fill='%23FBBC09' d='M220.654 220.695H115.788V115.828h104.866z'/%3E%3C/g%3E%3C/svg%3E"
        }
    ]
}
